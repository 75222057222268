import {
  ASC,
  DataTable,
  DateFormatter,
  getSortDir,
  includes,
  Link,
  PriceFormatter,
  SortFilters,
  sortWithFunction,
  toLower,
  urlReplace,
} from "best-common-react-2";
import React, { useEffect, useState } from "react";
import RouteConstants from "../../../constants/RouteConstants";
import { UserTicketRequestDTO } from "../../../types/UserRequest";
import GameDateFormatter from "../../tables/GameDateFormatter";

const RequestFormatter = ({ value }) => {
  const url = urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, { requestId: value });
  return <Link to={url}>{value}</Link>;
};

const Columns = [
  {
    name: "Request",
    key: "requestId",
    minWidth: 100,
    sortable: true,
    readonlyFormatter: RequestFormatter,
  },
  {
    name: "Game",
    key: "game",
    minWidth: 350,
    readonlyFormatter: GameDateFormatter,
    sortable: true,
  },
  {
    name: "# of Tickets",
    key: "quantity",
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Tickets Sent",
    key: "ticketsSentDisplay",
    minWidth: 200,
    sortable: false,
  },
  {
    name: "Total Ticket Cost",
    key: "totalTicketCost",
    minWidth: 150,
    sortable: true,
    readonlyFormatter: PriceFormatter,
  },
  {
    name: "Charge Date",
    key: "chargeDate",
    minWidth: 150,
    sortable: true,
    readonlyFormatter: DateFormatter,
  },
  {
    name: "Requester",
    key: "requester",
    minWidth: 300,
    sortable: true,
  },
  {
    name: "Department",
    key: "department",
    minWidth: 200,
    sortable: true,
  },
  {
    name: "Business",
    key: "business",
    minWidth: 200,
    sortable: true,
  },
];

const freeTextFilter = (data: UserTicketRequestDTO[], search?: string) =>
  search?.length
    ? data.filter(
        (data: UserTicketRequestDTO) =>
          includes(data.business, search) ||
          includes(data.requestId.toString(), search) ||
          includes(data.gamePk.toString(), search) ||
          includes(data.department, search) ||
          includes(data.quantity.toString(), search) ||
          includes(data.totalTicketCost.toString(), search) ||
          includes(data.requester, search) ||
          includes(data.orderCost.toString(), search)
      )
    : data;

type SeasonTicketsReportDataTableProps = {
  data: UserTicketRequestDTO[];
  reportData: any;
};

const SeasonTicketsReportDataTable = ({ data, reportData }: SeasonTicketsReportDataTableProps) => {
  const [displayData, setDisplayData] = useState<UserTicketRequestDTO[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<UserTicketRequestDTO>>({ key: "game", direction: ASC });

  useEffect(() => {
    if (data) {
      setDisplayData(
        sortWithFunction(
          freeTextFilter(data, reportData.search),
          sortFilters.key,
          getSortDir(sortFilters.direction),
          (value) => {
            if (sortFilters.key === "game") {
              return value.gameDate;
            } else {
              return value ? toLower(value) : "";
            }
          }
        )
      );
    } else {
      setDisplayData([]);
    }
  }, [data, sortFilters, reportData.search]);

  return (
    <div className="mt-2">
      <DataTable
        columns={Columns}
        data={displayData}
        sortColumn={sortFilters.key}
        sortDirection={sortFilters.direction}
        sortFunction={(key, direction) => setSortFilters({ key, direction })}
        statusTextOverride={(_selected, total) => {
          return `${total} Requests`;
        }}
        tableHeights={{
          autogrow: true,
        }}
      />
    </div>
  );
};

export default SeasonTicketsReportDataTable;
