import { DesktopTabletView, MobileView, Table, toMoney } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getProjectRequestsTotals } from "../../../api/RequesTixApi";
import { ProjectTotalsDTO } from "../../../types/Project";

const TableHolder = styled.div`
  width: 100%;
  max-width: 25rem;
`;

type ProjectRequestsSummaryTableProps = {
  projectId: number;
  startDate: string;
  endDate: string;
};

const ProjectRequestsSummaryTable = ({ projectId, startDate, endDate }: ProjectRequestsSummaryTableProps) => {
  const [data, setData] = useState<ProjectTotalsDTO>({
    projectId,
    approved: {},
    comped: {},
    card: {},
    dept: {},
    denied: {},
    total: {},
  });

  useEffect(() => {
    if (projectId && startDate && endDate) {
      getProjectRequestsTotals(projectId, startDate, endDate).then((data) => {
        setData(data);
      });
    }
  }, [projectId, startDate, endDate]);

  return (
    <TableHolder>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header style={{ width: "30%" }} />
            <Table.Header>Requests</Table.Header>
            <Table.Header>Ticket Qty</Table.Header>
            <DesktopTabletView>
              <Table.Header className="text-right pr-2">Cost</Table.Header>
            </DesktopTabletView>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.TdInfo>Approved</Table.TdInfo>
            <Table.Td>{data.approved.requests}</Table.Td>
            <Table.Td>{data.approved.ticketQty}</Table.Td>
            <DesktopTabletView>
              <Table.Td className="text-right">{toMoney(data.approved.cost)}</Table.Td>
            </DesktopTabletView>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>Comp</Table.TdInfo>
            <Table.Td>{data.comped.requests}</Table.Td>
            <Table.Td>{data.comped.ticketQty}</Table.Td>
            <DesktopTabletView>
              <Table.Td />
            </DesktopTabletView>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>Card</Table.TdInfo>
            <Table.Td>{data.card.requests}</Table.Td>
            <Table.Td>{data.card.ticketQty}</Table.Td>
            <DesktopTabletView>
              <Table.Td className="text-right">{toMoney(data.card.cost)}</Table.Td>
            </DesktopTabletView>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>Dept</Table.TdInfo>
            <Table.Td>{data.dept.requests}</Table.Td>
            <Table.Td>{data.dept.ticketQty}</Table.Td>
            <DesktopTabletView>
              <Table.Td className="text-right">{toMoney(data.dept.cost)}</Table.Td>
            </DesktopTabletView>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>Denied</Table.TdInfo>
            <Table.Td>{data.denied.requests}</Table.Td>
            <Table.Td>{data.denied.ticketQty}</Table.Td>
            <DesktopTabletView>
              <Table.Td />
            </DesktopTabletView>
          </Table.Row>
          <Table.Row>
            <Table.TdInfo>Total</Table.TdInfo>
            <Table.Td>{data.total.requests}</Table.Td>
            <Table.Td>{data.total.ticketQty}</Table.Td>
            <DesktopTabletView>
              <Table.Td className="text-right">{toMoney(data.total.cost)}</Table.Td>
            </DesktopTabletView>
          </Table.Row>
        </Table.Body>
      </Table>
      <MobileView>
        <Table className="mt-1">
          <Table.Head>
            <Table.Row>
              <Table.Header style={{ width: "30%" }} />
              <Table.Header className="text-right pr-2">Cost</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.TdInfo>Card Total</Table.TdInfo>
              <Table.Td className="text-right">{toMoney(data.card.cost)}</Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Dept Total</Table.TdInfo>
              <Table.Td className="text-right">{toMoney(data.dept.cost)}</Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Total</Table.TdInfo>
              <Table.Td className="text-right">{toMoney(data.total.cost)}</Table.Td>
            </Table.Row>
          </Table.Body>
        </Table>
      </MobileView>
    </TableHolder>
  );
};

export default ProjectRequestsSummaryTable;
