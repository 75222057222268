import {
  ASC,
  DataTable,
  DataTableColumn,
  getSortDir,
  PriceFormatter,
  SortDirection,
  SortFilters,
  sortWithFunction,
  toLower,
} from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { Finance } from "../../../types/Finance";

const Columns: DataTableColumn<Finance>[] = [
  {
    name: "Product Key",
    key: "productKey",
    minWidth: 250,
    sortable: true,
  },
  {
    name: "Product Name",
    key: "productName",
    minWidth: 200,
    sortable: true,
  },
  {
    name: "Unit Price",
    key: "unitPrice",
    minWidth: 90,
    readonlyFormatter: PriceFormatter,
    sortable: true,
    cellClass: "justify-content-center",
  },
  {
    name: "Total Quantity",
    key: "totalQuantity",
    minWidth: 90,
    sortable: true,
    cellClass: "justify-content-center",
  },
  {
    name: "Discount Amount Total",
    key: "discountAmountTotal",
    minWidth: 100,
    readonlyFormatter: PriceFormatter,
    sortable: true,
    cellClass: "justify-content-center",
  },
  {
    name: "Tax Amount Total",
    key: "taxAmountTotal",
    minWidth: 100,
    readonlyFormatter: PriceFormatter,
    sortable: true,
    cellClass: "justify-content-center",
  },
  {
    name: "Sales Gross Total",
    key: "salesGrossTotal",
    minWidth: 100,
    readonlyFormatter: PriceFormatter,
    sortable: true,
    cellClass: "justify-content-center",
  },
  {
    name: "Currency",
    key: "currency",
    minWidth: 75,
    sortable: true,
    cellClass: "justify-content-center",
  },
  {
    name: "Amount In Actual Currency",
    key: "amountInActualCurrency",
    readonlyFormatter: PriceFormatter,
    minWidth: 100,
    sortable: true,
    cellClass: "justify-content-center",
  },
];

type FinanceTableProps = {
  data: Finance[];
};

const FinanceTable = ({ data }: FinanceTableProps) => {
  const [displayData, setDisplayData] = useState([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<Finance>>({ key: "productName", direction: ASC });

  const onSort = (key: keyof Finance, direction: SortDirection) => setSortFilters({ key, direction });

  useEffect(() => {
    if (!!data) {
      setDisplayData(
        sortWithFunction(data, sortFilters.key, getSortDir(sortFilters.direction), (value) => {
          return value ? toLower(value) : "";
        })
      );
    } else {
      setDisplayData([]);
    }
  }, [data, sortFilters]);

  return (
    <DataTable
      columns={Columns}
      data={displayData}
      tableHeights={{
        headerHeight: 50,
        maxTableHeight: 300,
      }}
      sortColumn={sortFilters.key}
      sortDirection={sortFilters.direction}
      sortFunction={onSort}
      statusTextOverride={(_selected, total) => `${total} Transactions`}
    />
  );
};

export default FinanceTable;
