import {
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  FormColumn,
  getRawDate,
  Row,
  Typography,
  useLoading,
} from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { getCharges, getRefunds } from "../../../api/RequesTixApi";
import { Finance } from "../../../types/Finance";
import { previousMonth, yesterday } from "../../../util/DateObjUtil";
import { ReportHandlerTypeProps } from "../ReportHandler";
import FinanceTable from "./FinanceTable";

const FinanceReport = ({ reportData, setReportData }: ReportHandlerTypeProps) => {
  const { setLoading } = useLoading();
  const { startDate, endDate } = reportData;
  const [dateRange, setDateRange] = useState<DateRange<Date>>({
    start: !!startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
    end: !!endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
  });
  const [charges, setCharges] = useState<Finance[]>([]);
  const [refunds, setRefunds] = useState<Finance[]>([]);

  const updateDate = ({ start, end }: DateRange<Date>) => {
    if (!!start && !!end) {
      setReportData({
        ...reportData,
        startDate: formatDate(start, DATE_FORMATTER_STR),
        endDate: formatDate(end, DATE_FORMATTER_STR),
      });
    }
  };

  const getChargeData = async (startDate: string, endDate: string) => {
    try {
      const result: Finance[] = await getCharges(startDate, endDate);
      setCharges(result);
    } catch (e) {
      console.error(e);
    }
  };

  const getRefundData = async (startDate: string, endDate: string) => {
    try {
      const result: Finance[] = await getRefunds(startDate, endDate);
      setRefunds(result);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async (startDate: string, endDate: string) => {
    try {
      if (!!startDate && !!endDate) {
        setLoading(true);
        await Promise.all([getChargeData(startDate, endDate), getRefundData(startDate, endDate)]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const newDate: DateRange<Date> = {
      start: startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
      end: endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
    };
    setDateRange(newDate);
    if (!!startDate && !!endDate) {
      void getData(startDate, endDate);
    }
  }, [startDate, endDate]);

  return (
    <>
      <Row>
        <FormColumn width={4}>
          <DateRangeInput
            id="approval-date"
            label="Approval Date"
            value={dateRange}
            onChange={updateDate}
            maxRange={{
              months: 3,
            }}
            withPortal
            gutterBottom
          />
        </FormColumn>
      </Row>
      <div>
        <Typography variant="h5">Charges</Typography>
        <FinanceTable data={charges} />
      </div>
      <div className="mt-2">
        <Typography variant="h5">Refunds</Typography>
        <FinanceTable data={refunds} />
      </div>
    </>
  );
};

export default FinanceReport;
