import {
  DataTable,
  DataTableColumn,
  elementsAtIndex,
  FormatterType,
  IconFormatter,
  SortFilters,
  sortWithFunction,
  TextFormatter,
  toLower,
} from "best-common-react-2";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RouteConstants, { urlReplace } from "../../constants/RouteConstants";
import { DepartmentBySeasonDTO } from "../../types/Department";
import { Season } from "../../types/Season";
import { getDateText, getHoursBeforeText } from "../../util/TimeUtil";
import DepartmentAccordion from "./DepartmentAccordion";

export const AllotmentFormatter = ({ value, ...rest }: FormatterType<DepartmentBySeasonDTO>) => {
  const { amount = 0, unlimited = false } = value ?? {};
  return <TextFormatter {...rest} value={unlimited ? "unlimited" : amount} />;
};

export const AccessCutoffFormatter = ({ value = {}, ...rest }: FormatterType<DepartmentBySeasonDTO>) => {
  const { useDate, date, hoursBefore } = value;
  if (!!date || !!hoursBefore) {
    const val = useDate ? getDateText(date) : getHoursBeforeText(hoursBefore);
    return <TextFormatter {...rest} value={val} />;
  } else {
    return <TextFormatter {...rest} value="" />;
  }
};

export const OnOffFormatter = ({ value, ...rest }: FormatterType<DepartmentBySeasonDTO>) => (
  <TextFormatter {...rest} value={value ? "On" : "Off"} />
);

const tableSort = (values: DepartmentBySeasonDTO[], sortFilters: SortFilters<DepartmentBySeasonDTO>) => {
  if (sortFilters.direction === "NO DIRECTION") {
    return sortWithFunction(values, "departmentName", 1);
  } else if (sortFilters.direction === "ASC") {
    if (sortFilters.key === "personalAllotment" || sortFilters.key === "businessAllotment") {
      return sortWithFunction(values, sortFilters.key, 1, (a) => (a.unlimited ? Number.MAX_VALUE : a.amount));
    } else if (sortFilters.key === "access" || sortFilters.key === "cutoff") {
      return sortWithFunction(values, sortFilters.key, 1, (a) => (a.useDate ? a.date : `0000-00-00${a.hoursBefore}`));
    } else {
      return sortWithFunction(values, sortFilters.key, 1, toLower);
    }
  } else {
    if (sortFilters.key === "personalAllotment" || sortFilters.key === "businessAllotment") {
      return sortWithFunction(values, sortFilters.key, -1, (a) => (a.unlimited ? Number.MAX_VALUE : a.amount));
    } else if (sortFilters.key === "access" || sortFilters.key === "cutoff") {
      return sortWithFunction(values, sortFilters.key, -1, (a) => (a.useDate ? a.date : `0000-00-00${a.hoursBefore}`));
    } else {
      return sortWithFunction(values, sortFilters.key, -1, toLower);
    }
  }
};

type DepartmentTableProps = {
  values?: DepartmentBySeasonDTO[];
  searchTerm: string;
  selectedSeason?: Season;
  selected: DepartmentBySeasonDTO[];
  setSelected: (value: DepartmentBySeasonDTO[]) => void;
  loading: boolean;
};

const DepartmentTable = ({
  values = [],
  searchTerm,
  selectedSeason,
  selected,
  setSelected,
  loading,
}: DepartmentTableProps) => {
  const history = useHistory();
  const [departments, setDepartments] = useState<DepartmentBySeasonDTO[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<DepartmentBySeasonDTO>>({
    key: "departmentName",
    direction: "ASC",
  });

  const cols: DataTableColumn<DepartmentBySeasonDTO>[] = [
    {
      key: "editLink",
      name: "",
      width: 50,
      readonlyFormatter: IconFormatter,
      icon: "fa-pencil",
      cellClass: "justify-content-center",
      onClick: (index: number) => {
        const value = departments[index];
        history.push(
          urlReplace(RouteConstants.ADMIN.DEPARTMENTS_EDIT, {
            departmentId: value.departmentId,
          })
        );
      },
    },
    { key: "departmentName", name: "Name", width: 300, sortable: true },
    {
      key: "personalAllotment",
      name: "Personal",
      width: 100,
      cellClass: "justify-content-center",
      readonlyFormatter: AllotmentFormatter,
      sortable: true,
    },
    {
      key: "businessAllotment",
      name: "Business",
      width: 100,
      cellClass: "justify-content-center",
      readonlyFormatter: AllotmentFormatter,
      sortable: true,
    },
    { key: "access", name: "Event Access (ET)", width: 250, readonlyFormatter: AccessCutoffFormatter, sortable: true },
    {
      key: "personalCutoff",
      name: "Personal Event Cutoff (ET)",
      width: 250,
      readonlyFormatter: AccessCutoffFormatter,
      sortable: true,
    },
    {
      key: "businessCutoff",
      name: "Business Event Cutoff (ET)",
      width: 250,
      readonlyFormatter: AccessCutoffFormatter,
      sortable: true,
    },
    {
      key: "personalRequests",
      name: "Personal Requests",
      readonlyFormatter: OnOffFormatter,
      cellClass: "justify-content-center",
      sortable: true,
      width: 150,
    },
  ];

  const isSelected = useCallback(
    (rowIndex: number): boolean => {
      const department = values[rowIndex];
      return !!selected.find((s: DepartmentBySeasonDTO) => s.departmentId === department?.departmentId);
    },
    [values, selected]
  );

  const onSelectionChange = (rowIndexes: number[], isSelected: boolean) => {
    const depts: DepartmentBySeasonDTO[] = elementsAtIndex(values, rowIndexes);
    if (isSelected) {
      setSelected([...selected, ...depts]);
    } else {
      const deptIds = depts.map((d) => d.departmentId);
      setSelected(selected.filter((s: DepartmentBySeasonDTO) => !deptIds.includes(s.departmentId)));
    }
  };

  const statusTextOverride = (selectedCount: number, totalCount: number) => {
    if (!!selectedCount) {
      return `${selectedCount} of ${totalCount} Departments selected`;
    } else {
      return `${totalCount} Departments`;
    }
  };

  useEffect(() => {
    const deps = values
      .map((department) => ({
        editLink:
          urlReplace(RouteConstants.ADMIN.DEPARTMENTS_EDIT, { departmentId: department.departmentId }) +
          `?year=${selectedSeason.year}`,
        personalCutoff: department.cutoff.personal,
        businessCutoff: department.cutoff.business,
        ...department,
      }))
      .filter((department) =>
        Object.values(department).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      );
    setDepartments(tableSort(deps, sortFilters));
  }, [values, selected, searchTerm, selectedSeason]);

  useEffect(() => {
    setDepartments(tableSort(departments, sortFilters));
  }, [sortFilters]);

  return (
    <DataTable
      data={departments}
      columns={cols}
      sortFunction={(key, direction) => {
        const k = key as keyof DepartmentBySeasonDTO;
        setSortFilters({ key: k, direction });
      }}
      statusTextOverride={statusTextOverride}
      accordion={DepartmentAccordion}
      sortColumn={sortFilters["key"]}
      sortDirection={sortFilters["direction"]}
      rowSelection={{
        isSelected,
        onSelectionChange,
      }}
      tableHeights={{
        autogrow: true,
        maxHeightOffset: 350,
      }}
      loading={loading}
    />
  );
};

export default DepartmentTable;
