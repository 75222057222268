import { DataTable, DataTableColumn, FormColumn, Row, Select, useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { getRequestsByVenueForSeasonId } from "../../../api/RequesTixApi";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { RequestsByVenueDTO } from "../../../types/RequestsByVenueDTO";
import { getSeasonOption } from "../../../util/DropdownUtil";
import { ReportHandlerTypeProps } from "../ReportHandler";

const Columns: DataTableColumn<RequestsByVenueDTO>[] = [
  {
    name: "Team Name",
    key: "teamName",
  },
  {
    name: "Venue Name",
    key: "venueName",
  },
  {
    name: "Approved Personal Requests",
    key: "approvedPersonalRequests",
  },
  {
    name: "Total Personal Requests",
    key: "personalRequests",
  },
  {
    name: "Approved Business Requests",
    key: "approvedBusinessRequests",
  },
  {
    name: "Total Business Requests",
    key: "businessRequests",
  },
];

const RequestsByVenueReport = ({ reportData, setReportData }: ReportHandlerTypeProps) => {
  const { setLoading } = useLoading();
  const { seasonsOptions } = useDropdowns();
  const { seasonId } = reportData;
  const [requestsByVenue, setRequestsByVenue] = useState<RequestsByVenueDTO[]>([]);

  const getData = async (seasonId) => {
    try {
      setLoading(true);
      const result: RequestsByVenueDTO[] = await getRequestsByVenueForSeasonId(seasonId);
      setRequestsByVenue(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key, data) => {
    setReportData({ ...reportData, [key]: data });
  };

  useEffect(() => {
    if (!!seasonId) {
      void getData(seasonId);
    }
  }, [seasonId]);

  return (
    <div>
      <Row>
        <FormColumn width={4}>
          <Select
            id="season"
            label="Season"
            options={seasonsOptions}
            value={getSeasonOption(seasonId, seasonsOptions)}
            onChange={(value) => {
              onChange("seasonId", value.value.seasonId);
            }}
          />
        </FormColumn>
      </Row>
      <DataTable className="mt-2" data={requestsByVenue} columns={Columns} />
    </div>
  );
};

export default RequestsByVenueReport;
