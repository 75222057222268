import * as H from "history";
import React from "react";
import ReportConstants from "../../constants/ReportConstants";
import { ReportDataDTO, Reports } from "../../types/Reports";
import CloseCutoffReport from "./closeCutoff/CloseCutoffReport";
import FinanceReport from "./finance/FinanceReport";
import ProjectRequestsReport from "./projectRequests/ProjectRequestsReport";
import ReconciliationReport from "./reconciliation/ReconciliationReport";
import RequestsByVenueReport from "./requestsByVenue/RequestsByVenueReport";
import SeasonTicketsReport from "./seasonTickets/SeasonTicketsReport";
import TodaysTicketsReport from "./todaysTickets/TodaysTicketsReport";
import UserRequestsReport from "./userRequests/UserRequestsReport";

export type ReportHandlerTypeProps = {
  reportData: ReportDataDTO;
  setReportData: (value: ReportDataDTO) => void;
  setCanExport: (value: boolean) => void;
  location: H.Location;
};

type ReportHandlerProps = ReportHandlerTypeProps & {
  report: Reports;
};

const ReportHandler = ({ report, reportData, setReportData, setCanExport, location }: ReportHandlerProps) => {
  switch (report.reportId) {
    case ReportConstants.USER_REQUESTS_REPORT_ID:
      return (
        <UserRequestsReport
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      );
    case ReportConstants.PROJECT_REQUESTS_REPORT_ID:
      return (
        <ProjectRequestsReport
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      );
    case ReportConstants.RECONCILIATION_REPORT_ID:
      return (
        <ReconciliationReport
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      );
    case ReportConstants.FINANCE_REPORT_ID:
      return (
        <FinanceReport
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      );
    case ReportConstants.SEASON_TICKETS:
      return (
        <SeasonTicketsReport
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      );
    case ReportConstants.REQUESTS_BY_VENUE:
      return (
        <RequestsByVenueReport
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      );
    case ReportConstants.CLOSE_CUTOFF:
      return (
        <CloseCutoffReport
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      );
    case ReportConstants.TODAYS_TICKETS:
      return (
        <TodaysTicketsReport
          reportData={reportData}
          setReportData={setReportData}
          location={location}
          setCanExport={setCanExport}
        />
      );
    default:
      return null;
  }
};

export default ReportHandler;
