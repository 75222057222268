import { DataTableDataType, FormatterType, IconFormatter } from "best-common-react-2";
import React from "react";

export type ExportFormatterValue<T> = DataTableDataType & {
  value: T;
  onClick: (value: T) => void;
};

const ExportFormatter = <T extends DataTableDataType>({ value: { value, onClick }, ...rest }: FormatterType<T>) => (
  <IconFormatter {...rest} value={value} icon="fa-download" onClick={() => onClick(value)} />
);

export default ExportFormatter;
