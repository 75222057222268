import { Button, Hint, useLoading } from "best-common-react-2";
import React, { useCallback, useEffect, useState } from "react";
import { revokeSuiteDigitalTickets, sendSuiteDigitalTickets } from "../../../api/RequesTixApi";
import { DigitalTicketDeliveryDTO } from "../../../types/DigitalTickets";
import { BoxOfficeTicketDTO } from "../../../types/Tickets";
import { fetchDigitalTickets } from "../../../util/DigitalTicketUtil";
import RevokeTicketsModal from "../../tickets/RevokeTicketsModal";
import TicketDisplay from "../../tickets/TicketDisplay";
import TicketSelectionModal from "../../tickets/TicketSelectionModal";
import { AdminSuiteActionsProps } from "./AdminSuiteRequestsActions";

const AdminSuiteDigitalTickets = ({
  actionState,
  setActionState,
  request,
  game,
  refreshRequest,
}: AdminSuiteActionsProps) => {
  const { setLoading } = useLoading();
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [revokeOpen, setRevokeOpen] = useState<boolean>(false);
  const [availableTickets, setAvailableTickets] = useState<BoxOfficeTicketDTO[]>([]);
  const [selectedTickets, setSelectedTickets] = useState<BoxOfficeTicketDTO[]>([]);

  const refetchTickets = useCallback(
    async (refresh = false) => {
      const homeTeamId = game.teams?.home?.team?.id;
      const tickets: BoxOfficeTicketDTO[] = await fetchDigitalTickets(
        game.gamePk,
        homeTeamId,
        actionState?.digitalTickets?.forwardId,
        refresh
      );
      setAvailableTickets(tickets);
    },
    [game, actionState]
  );

  const fetchTickets = async (gamePk: number, homeTeamId: number, existingForwardId?: string, refresh = false) => {
    const tickets: BoxOfficeTicketDTO[] = await fetchDigitalTickets(gamePk, homeTeamId, existingForwardId, refresh);
    setAvailableTickets(tickets);
  };

  const changedSelectedTicketIds = useCallback(
    (ticketIds: string[]) => {
      const dtd: DigitalTicketDeliveryDTO = actionState.digitalTickets ?? {};
      const newDtd: DigitalTicketDeliveryDTO = { ...dtd, ticketIds: ticketIds };
      setActionState({
        ...actionState,
        digitalTickets: newDtd,
      });
    },
    [actionState]
  );

  const sendTickets = useCallback(async () => {
    try {
      setLoading(true);
      await sendSuiteDigitalTickets({
        request,
        actionState,
      });
      refreshRequest();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [request, actionState]);

  const revokeTickets = useCallback(async () => {
    try {
      setLoading(true);
      await revokeSuiteDigitalTickets({
        request,
        actionState,
      });
      refreshRequest();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [request, actionState]);

  useEffect(() => {
    if (!!game) {
      const homeTeamId = game.teams?.home?.team?.id;
      void fetchTickets(game.gamePk, homeTeamId, actionState?.digitalTickets?.forwardId, false);
    }
  }, [game?.gamePk, game?.teams, actionState?.digitalTickets?.forwardId]);

  useEffect(() => {
    const ticketIds = actionState?.digitalTickets?.ticketIds ?? [];
    if (!!availableTickets?.length) {
      setSelectedTickets(availableTickets.filter((t: BoxOfficeTicketDTO) => ticketIds.includes(t.ticketID)));
    } else {
      setSelectedTickets([]);
    }
  }, [availableTickets, actionState?.digitalTickets?.ticketIds]);

  const haveBeenAssigned = !!actionState?.digitalTickets?.digitalTicketDeliveryId;

  return (
    <>
      <RevokeTicketsModal
        isOpen={revokeOpen}
        close={() => setRevokeOpen(false)}
        tickets={selectedTickets}
        onRevoke={revokeTickets}
        refreshTickets={refetchTickets}
      />
      <TicketSelectionModal
        isOpen={selectOpen}
        close={() => {
          setSelectOpen(false);
        }}
        tickets={availableTickets}
        ticketQuantity={actionState.numberOfTickets}
        selectedTickets={actionState.digitalTickets?.ticketIds}
        setSelectedTickets={changedSelectedTicketIds}
        save={sendTickets}
        refreshTickets={refetchTickets}
      />

      {haveBeenAssigned ? (
        <div>
          {selectedTickets.map((t: BoxOfficeTicketDTO) => (
            <TicketDisplay data={t} key={t.ticketID} />
          ))}
          <Button
            variant="default"
            onClick={() => {
              setRevokeOpen(true);
            }}
          >
            Revoke Tickets
          </Button>
        </div>
      ) : (
        <div>
          <Button
            variant="link"
            onClick={() => {
              setSelectOpen(true);
            }}
          >
            Assign Tickets
          </Button>
          <Hint>
            {selectedTickets.length} of {actionState.numberOfTickets} selected
          </Hint>
        </div>
      )}
    </>
  );
};

export default AdminSuiteDigitalTickets;
